var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useContext } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './css/datepicker.css';
import { ThemeContext } from './common/theme';
import { dashesToText } from './Input';
import { Description, Label } from './Typography';
export var DateTimePicker = function (_a) {
    var name = _a.name, _b = _a.type, type = _b === void 0 ? 'text' : _b, _c = _a.size, size = _c === void 0 ? 'small' : _c, _d = _a.label, label = _d === void 0 ? dashesToText(name) : _d, _e = _a.placeholder, placeholder = _e === void 0 ? label : _e, _f = _a.outlineColor, outlineColor = _f === void 0 ? 'primary100' : _f, description = _a.description, _g = _a.errorText, errorText = _g === void 0 ? description : _g, _h = _a.valid, valid = _h === void 0 ? true : _h, _j = _a.disabled, disabled = _j === void 0 ? false : _j, _k = _a.onFocus, onFocus = _k === void 0 ? function () { } : _k, _l = _a.onBlur, onBlur = _l === void 0 ? function () { } : _l, _m = _a.onChange, onChange = _m === void 0 ? function () { } : _m, style = _a.style, className = _a.className, others = __rest(_a, ["name", "type", "size", "label", "placeholder", "outlineColor", "description", "errorText", "valid", "disabled", "onFocus", "onBlur", "onChange", "style", "className"]);
    var theme = useContext(ThemeContext);
    return (React.createElement(Container, { style: style, className: className },
        label !== '' && (React.createElement(Label, { htmlFor: name, text: label, color: "black", style: { paddingBottom: '5px' } })),
        React.createElement(StyledDatePicker, __assign({ focusColor: theme[outlineColor], textColor: theme['black'], blurredColor: theme['gray100'], errorColor: valid ? undefined : theme['danger100'], disabled: disabled, timeFormat: "p", timeIntervals: 15, dateFormat: others.showTimeSelect || others.showTimeSelectOnly ? 'Pp' : 'P', onChange: onChange }, others)),
        description && React.createElement(StyledDescription, { color: "gray100", text: description }),
        !valid && React.createElement(StyledDescription, { color: "danger100", text: errorText })));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  > * {\n    width: 100%;\n  }\n"], ["\n  width: 100%;\n  > * {\n    width: 100%;\n  }\n"])));
var StyledDescription = styled(Description)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 5px;\n  font-weight: 400;\n  font-size: 12px;\n"], ["\n  margin-top: 5px;\n  font-weight: 400;\n  font-size: 12px;\n"])));
// This style injects CSS variables, to be used in datepicker.css
var StyledDatePicker = styled(DatePicker)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  --primary-color: ", ";\n  --color: ", ";\n  --border-color: ", ";\n  --focus-border-color: ", ";\n  --placeholder-color: ", ";\n"], ["\n  --primary-color: ", ";\n  --color: ", ";\n  --border-color: ", ";\n  --focus-border-color: ", ";\n  --placeholder-color: ", ";\n"])), function (props) { return props.focusColor; }, function (props) { return (props.disabled ? props.blurredColor : props.textColor); }, function (props) { return props.errorColor || props.blurredColor; }, function (props) { return props.errorColor || props.focusColor; }, function (props) { return props.blurredColor; });
var templateObject_1, templateObject_2, templateObject_3;
