var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from './common/theme';
import { Button } from './Button';
import { Subtitle } from './Typography';
export var Radio = function (_a) {
    var value = _a.value, leftText = _a.leftText, rightText = _a.rightText, _b = _a.textMarginPx, textMarginPx = _b === void 0 ? 12 : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, onClick = _a.onClick, style = _a.style, className = _a.className;
    var theme = useContext(ThemeContext);
    return (React.createElement(Container, { className: className },
        leftText && (React.createElement(Subtitle, { color: disabled ? 'gray100' : 'black', text: leftText, style: { marginRight: textMarginPx + "px" } })),
        React.createElement(StyledButton, { onClick: function (ev) {
                ev.preventDefault();
                if (onClick)
                    onClick();
            }, disabled: disabled, radius: 24, size: "icon", color: "primary", style: style, outline: !value, outlineSize: "0.5px", outlineColor: theme['primary100'], iconLeft: value ? 'ok' : undefined }),
        rightText && (React.createElement(Subtitle, { color: disabled ? 'gray100' : 'black', text: rightText, style: { marginLeft: textMarginPx + "px" } }))));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var StyledButton = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 24px;\n  width: 24px;\n"], ["\n  height: 24px;\n  width: 24px;\n"])));
export var Radios = function (_a) {
    var value = _a.value, choices = _a.choices, onChange = _a.onChange, _b = _a.left, left = _b === void 0 ? false : _b, _c = _a.column, column = _c === void 0 ? false : _c, _d = _a.disabled, disabled = _d === void 0 ? false : _d, className = _a.className;
    return (React.createElement(RadioContainer, { direction: column ? 'column' : 'row', alignRight: column && left, className: className }, choices.map(function (_a) {
        var key = _a.key, label = _a.label, specificDisabled = _a.disabled;
        return (React.createElement(Radio, { key: key, leftText: left ? label : undefined, rightText: !left ? label : undefined, value: value === key, onClick: function () { return onChange(key); }, disabled: disabled || specificDisabled }));
    })));
};
var RadioContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: ", ";\n  align-items: ", ";\n  justify-content: flex-start;\n  > :nth-child(n + 2) {\n    margin-top: ", ";\n    margin-left: ", ";\n  }\n"], ["\n  display: flex;\n  flex-direction: ", ";\n  align-items: ",
    ";\n  justify-content: flex-start;\n  > :nth-child(n + 2) {\n    margin-top: ", ";\n    margin-left: ", ";\n  }\n"])), function (props) { return props.direction; }, function (props) {
    return props.direction === 'column' && props.alignRight ? 'flex-end' : 'flex-start';
}, function (props) { return (props.direction === 'row' ? 'initial' : '20px'); }, function (props) { return (props.direction === 'column' ? 'initial' : '30px'); });
var templateObject_1, templateObject_2, templateObject_3;
