var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from './common/theme';
import { Button } from './Button';
import { Subtitle } from './Typography';
export var Checkbox = function (_a) {
    var value = _a.value, leftText = _a.leftText, rightText = _a.rightText, _b = _a.textMarginPx, textMarginPx = _b === void 0 ? 12 : _b, onChange = _a.onChange, style = _a.style, className = _a.className;
    var theme = useContext(ThemeContext);
    return (React.createElement(Container, { className: className },
        leftText && (React.createElement(Subtitle, { color: "black", text: leftText, style: { marginRight: textMarginPx + "px" } })),
        React.createElement(StyledButton, { onClick: function (ev) {
                ev.preventDefault();
                if (onChange)
                    onChange(!value);
            }, radius: 4, size: "icon", color: "primary", style: style, outline: !value, outlineSize: "0.5px", outlineColor: theme['primary100'], iconLeft: value ? 'ok' : undefined }),
        rightText && (React.createElement(Subtitle, { color: "black", text: rightText, style: { marginLeft: textMarginPx + "px" } }))));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var StyledButton = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 24px;\n  width: 24px;\n"], ["\n  height: 24px;\n  width: 24px;\n"])));
var templateObject_1, templateObject_2;
