import React, { useContext } from 'react';
export var defaultTheme = {
    black: '#222222',
    white: '#ffffff',
    primary100: '#23d38a',
    primary50: '#85ebc1',
    primary20: '#d2f7e8',
    secondary100: '#302387',
    secondary50: '#968fc1',
    secondary20: '#d7d4ea',
    tertiary100: '#ff3796',
    tertiary50: '#ff9ccb',
    tertiary20: '#ffd9eb',
    gray100: '#828894',
    gray50: '#e5e7eb',
    gray20: '#f7f7f7',
    success100: '#a1dd70',
    success50: '#d0edb8',
    success20: '#e8f6dd',
    warning100: '#fdd043',
    warning50: '#fee69a',
    warning20: '#fff6da',
    danger100: '#ff5959',
    danger50: '#feaeae',
    danger20: '#fedcdc',
};
export var ThemeContext = React.createContext(defaultTheme);
export var useTheme = function () {
    return useContext(ThemeContext);
};
