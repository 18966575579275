var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from 'react';
import styled from 'styled-components';
import 'microtip/microtip.css';
import { ThemeContext } from './common/theme';
import { Icon } from './Icon';
var buttonColors = {
    primary: { full: 'primary100', disabled: 'primary50' },
    secondary: { full: 'secondary100', disabled: 'secondary50' },
    success: { full: 'success100', disabled: 'success50' },
    warning: { full: 'warning100', disabled: 'warning50' },
    danger: { full: 'danger100', disabled: 'danger50' },
    gray: { full: 'gray100', disabled: 'gray50' },
};
export var Button = function (_a) {
    var text = _a.text, _b = _a.onClick, onClick = _b === void 0 ? function () { } : _b, _c = _a.size, size = _c === void 0 ? 'medium' : _c, _d = _a.radius, radius = _d === void 0 ? 0 : _d, _e = _a.color, color = _e === void 0 ? 'primary' : _e, _f = _a.outline, outline = _f === void 0 ? false : _f, _g = _a.outlineSize, outlineSize = _g === void 0 ? '3px' : _g, _h = _a.disabled, disabled = _h === void 0 ? false : _h, _j = _a.loading, loading = _j === void 0 ? false : _j, iconLeft = _a.iconLeft, iconRight = _a.iconRight, type = _a.type, tooltip = _a.tooltip, _k = _a.tooltipPosition, tooltipPosition = _k === void 0 ? 'top' : _k, tooltipSize = _a.tooltipSize, style = _a.style, className = _a.className;
    var theme = useContext(ThemeContext);
    var _l = buttonColors[color], full = _l.full, disabledColor = _l.disabled;
    var leftIcon = loading ? 'loader' : iconLeft;
    var rightIcon = loading ? undefined : iconRight;
    var iconSize = size === 'icon' ? '.9em' : '1.2em';
    return (React.createElement(StyledButton, __assign({ color: theme[loading ? 'gray100' : disabled ? disabledColor : full] }, { size: size, radius: radius, outline: outline, outlineSize: outlineSize, disabled: disabled, type: type }, { onClick: disabled ? function (ev) { } : onClick, style: style, className: className, "aria-label": tooltip, "data-microtip-position": tooltip ? tooltipPosition : undefined, "data-microtip-size": tooltip ? tooltipSize : undefined, role: tooltip ? 'tooltip' : undefined }),
        leftIcon && React.createElement(Icon, { name: leftIcon, size: iconSize, style: { margin: 0 } }),
        React.createElement("span", { style: {
                marginLeft: leftIcon && text ? '7px' : 0,
                marginRight: rightIcon && text ? '7px' : 0,
                transition: 'margin 0.5s ease',
            } }, text),
        rightIcon && React.createElement(Icon, { name: rightIcon, size: iconSize, style: { margin: 0 } })));
};
var paddings = {
    icon: { x: 0, y: 0 },
    small: { x: 8, y: 7 },
    medium: { x: 10, y: 14 },
    large: { x: 20, y: 19 },
};
var StyledButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: ", ";\n  height: ", ";\n  font-family: 'Open Sans', sans-serif;\n  font-size: 15px;\n  font-weight: 600;\n  outline: none;\n  cursor: pointer;\n  border: ", ";\n  background-color: ", ";\n  color: ", ";\n  border-radius: ", "px;\n  padding-left: ", "px;\n  padding-right: ", "px;\n  padding-top: ", "px;\n  padding-bottom: ", "px;\n  transition: opacity 0.3s ease, color 0.5s ease, background-color 0.2s ease;\n  :hover {\n    ", ";\n    cursor: ", ";\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: ", ";\n  height: ", ";\n  font-family: 'Open Sans', sans-serif;\n  font-size: 15px;\n  font-weight: 600;\n  outline: none;\n  cursor: pointer;\n  border: ", ";\n  background-color: ", ";\n  color: ", ";\n  border-radius: ", "px;\n  padding-left: ", "px;\n  padding-right: ", "px;\n  padding-top: ", "px;\n  padding-bottom: ", "px;\n  transition: opacity 0.3s ease, color 0.5s ease, background-color 0.2s ease;\n  :hover {\n    ", ";\n    cursor: ", ";\n  }\n"])), function (props) { return (props.size !== 'icon' ? '100%' : '24px'); }, function (props) { return (props.size !== 'icon' ? 'auto' : '24px'); }, function (props) { return (props.outline ? props.outlineSize + " solid " + props.color + ";" : 'none'); }, function (props) { return (props.outline ? 'white' : props.color); }, function (props) { return (props.outline ? props.color : 'white'); }, function (props) { return props.radius; }, function (props) { return paddings[props.size].x; }, function (props) { return paddings[props.size].x; }, function (props) { return paddings[props.size].y; }, function (props) { return paddings[props.size].y; }, function (props) { return (props.disabled ? '' : 'opacity: 0.8'); }, function (props) { return (props.disabled ? 'not-allowed' : 'pointer'); });
var templateObject_1;
