var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from './common/theme';
import { Icon } from './Icon';
import ReactModal from 'react-modal';
ReactModal.setAppElement('#root');
export var Modal = function (_a) {
    var title = _a.title, _b = _a.open, open = _b === void 0 ? false : _b, _c = _a.color, color = _c === void 0 ? 'gray50' : _c, onClose = _a.onClose, className = _a.className, children = _a.children;
    var theme = useContext(ThemeContext);
    return (React.createElement(ReactModal, { isOpen: open, onRequestClose: onClose, style: {
            overlay: {
                backgroundColor: 'rgba(0,0,0,0.40)',
                zIndex: 10,
            },
            content: {
                padding: 0,
                margin: 0,
                backgroundColor: theme[color],
            },
        }, className: className },
        React.createElement(Header, { textColor: theme['black'] },
            React.createElement("span", null, title),
            React.createElement(ExitIcon, { name: "exit", size: "24px", color: "black", onClick: onClose })),
        children));
};
var Header = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  box-sizing: border-box;\n  flex-flow: row nowrap;\n  justify-content: space-between;\n  padding-left: 20px;\n  padding-right: 34px;\n  padding-top: 20px;\n  padding-bottom: 20px;\n  align-items: center;\n  width: 100%;\n  font-family: 'Open Sans', sans-serif;\n  font-weight: 800;\n  font-size: 18px;\n  color: ", ";\n  user-select: none;\n"], ["\n  display: flex;\n  box-sizing: border-box;\n  flex-flow: row nowrap;\n  justify-content: space-between;\n  padding-left: 20px;\n  padding-right: 34px;\n  padding-top: 20px;\n  padding-bottom: 20px;\n  align-items: center;\n  width: 100%;\n  font-family: 'Open Sans', sans-serif;\n  font-weight: 800;\n  font-size: 18px;\n  color: ", ";\n  user-select: none;\n"])), function (props) { return props.textColor; });
var ExitIcon = styled(Icon)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  cursor: pointer;\n  user-select: none;\n"], ["\n  cursor: pointer;\n  user-select: none;\n"])));
var templateObject_1, templateObject_2;
