var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from './common/theme';
import { Icon } from './Icon';
export var Paginator = function (_a) {
    var _b = _a.currentPage, currentPage = _b === void 0 ? 0 : _b, _c = _a.totalPages, totalPages = _c === void 0 ? 1 : _c, _d = _a.numbersForSide, numbersForSide = _d === void 0 ? 2 : _d, _e = _a.onPageSelect, onPageSelect = _e === void 0 ? function () { } : _e, _f = _a.selectedColor, selectedColor = _f === void 0 ? 'primary100' : _f, style = _a.style;
    var theme = useContext(ThemeContext);
    var leftSide = numbersForSide;
    var rightSide = numbersForSide;
    if (currentPage < numbersForSide) {
        // Beginning
        leftSide = currentPage;
    }
    if (currentPage > totalPages - 1 - numbersForSide) {
        // End
        rightSide = totalPages - 1 - currentPage;
    }
    var leftNumbers = Array.from(Array(leftSide).keys()).map(function (i) { return currentPage - leftSide + i + 1; });
    var rightNumbers = Array.from(Array(rightSide).keys()).map(function (i) { return currentPage + 1 + i + 1; });
    return (React.createElement(Container, { style: style },
        currentPage !== 0 ? (React.createElement(React.Fragment, null,
            React.createElement(Button, { onClick: function () { return onPageSelect(0); } },
                React.createElement(Icon, { name: "arrow-left-duble", size: "24px", color: "gray50" })),
            React.createElement(Button, { onClick: function () { return onPageSelect(currentPage - 1); } },
                React.createElement(Icon, { name: "arrow-left", size: "24px", color: "gray50" })))) : (React.createElement("div", { style: { width: '48px', height: '24px' } })),
        leftNumbers.map(function (num) { return (React.createElement(PaginatorNumber, { key: num, textColor: theme['gray100'], background: "transparent", onClick: function () { return onPageSelect(num - 1); } }, num)); }),
        React.createElement(PaginatorNumber, { textColor: theme['white'], background: theme[selectedColor] }, currentPage + 1),
        rightNumbers.map(function (num) { return (React.createElement(PaginatorNumber, { key: num, textColor: theme['gray100'], background: "transparent", onClick: function () { return onPageSelect(num - 1); } }, num)); }),
        currentPage !== totalPages - 1 ? (React.createElement(React.Fragment, null,
            React.createElement(Button, { onClick: function () { return onPageSelect(currentPage + 1); } },
                React.createElement(Icon, { name: "arrow-right", size: "24px", color: "gray50" })),
            React.createElement(Button, { onClick: function () { return onPageSelect(totalPages - 1); } },
                React.createElement(Icon, { name: "arrow-right-duble", size: "24px", color: "gray50" })))) : (React.createElement("div", { style: { width: '48px', height: '24px' } }))));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: none;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  * {\n    margin-left: 11px;\n  }\n"], ["\n  border: none;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  * {\n    margin-left: 11px;\n  }\n"])));
var PaginatorNumber = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Open Sans', sans-serif;\n  font-size: 13px;\n  font-weight: 600;\n  border: none;\n  outline: none;\n  color: ", ";\n  background-color: ", ";\n  border-radius: 50%;\n  padding: 0;\n  height: 24px;\n  width: 24px;\n"], ["\n  font-family: 'Open Sans', sans-serif;\n  font-size: 13px;\n  font-weight: 600;\n  border: none;\n  outline: none;\n  color: ", ";\n  background-color: ", ";\n  border-radius: 50%;\n  padding: 0;\n  height: 24px;\n  width: 24px;\n"])), function (props) { return props.textColor; }, function (props) { return props.background; });
var Button = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  cursor: pointer;\n  outline: none;\n  border: none;\n  background-color: transparent;\n  margin: 0;\n  padding: 0;\n"], ["\n  cursor: pointer;\n  outline: none;\n  border: none;\n  background-color: transparent;\n  margin: 0;\n  padding: 0;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
