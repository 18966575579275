var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from 'react';
import styled from 'styled-components';
import { compose } from 'ramda';
import { ThemeContext } from './common/theme';
var capitalize = function (s) { return (s.length === 0 ? '' : s.charAt(0).toUpperCase() + s.slice(1)); };
var splitDashes = function (s) { return s.split(/_|-/); };
var join = function (s) { return s.join(' '); };
export var dashesToText = compose(join, splitDashes, capitalize);
var noop = function () {
    var s = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        s[_i] = arguments[_i];
    }
};
export function Input(_a) {
    var name = _a.name, _b = _a.type, type = _b === void 0 ? 'text' : _b, autocomplete = _a.autocomplete, _c = _a.size, size = _c === void 0 ? 'small' : _c, _d = _a.label, label = _d === void 0 ? dashesToText(name) : _d, _e = _a.placeholder, placeholder = _e === void 0 ? label : _e, _f = _a.outlineColor, outlineColor = _f === void 0 ? 'primary100' : _f, description = _a.description, _g = _a.errorText, errorText = _g === void 0 ? description : _g, _h = _a.valid, valid = _h === void 0 ? true : _h, _j = _a.disabled, disabled = _j === void 0 ? false : _j, _k = _a.onFocus, onFocus = _k === void 0 ? noop : _k, _l = _a.onBlur, onBlur = _l === void 0 ? noop : _l, onChange = _a.onChange, value = _a.value, rows = _a.rows, columns = _a.columns, rightWidget = _a.rightWidget, _m = _a.disabledBackgroundColor, disabledBackgroundColor = _m === void 0 ? 'gray20' : _m, style = _a.style, className = _a.className;
    var theme = useContext(ThemeContext);
    return (React.createElement(Container, { style: style, className: className },
        label !== '' && (React.createElement(Label, { htmlFor: name, color: theme['black'] }, label)),
        rows || columns ? (React.createElement(StyledTextArea, __assign({}, { name: name, type: type, placeholder: placeholder, onFocus: onFocus, onBlur: onBlur, disabled: disabled }, { autoComplete: autocomplete, onChange: onChange && !disabled ? function (ev) { return onChange(ev.target.value); } : noop, value: value, padding: size, focusColor: theme[outlineColor], textColor: theme['black'], blurredColor: theme['gray100'], errorColor: valid ? undefined : theme['danger100'], backgroundColor: theme['white'], disabledBackgroundColor: theme[disabledBackgroundColor], rows: rows, cols: columns }))) : (React.createElement(StyledInputContainer, { padding: size, focusColor: theme[outlineColor], textColor: theme['black'], blurredColor: theme['gray100'], errorColor: valid ? undefined : theme['danger100'], disabled: disabled, backgroundColor: theme['white'], disabledBackgroundColor: theme[disabledBackgroundColor] },
            React.createElement(StyledInput, __assign({}, { name: name, type: type, placeholder: placeholder, onFocus: onFocus, onBlur: onBlur, disabled: disabled }, { size: 1, autoComplete: autocomplete, onChange: onChange && !disabled ? function (ev) { return onChange(ev.target.value); } : noop, value: value, padding: size, focusColor: theme[outlineColor], textColor: theme['black'], blurredColor: theme['gray100'], errorColor: valid ? undefined : theme['danger100'], backgroundColor: theme['white'], disabledBackgroundColor: theme[disabledBackgroundColor] })),
            rightWidget)),
        description && React.createElement(Description, { color: theme['gray100'] }, description),
        !valid && React.createElement(Description, { color: theme['danger100'] }, errorText)));
}
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  > * {\n    width: 100%;\n  }\n"], ["\n  width: 100%;\n  > * {\n    width: 100%;\n  }\n"])));
var paddings = {
    small: { x: 8, y: 7 },
    large: { x: 20, y: 19 },
};
export var StyledInput = styled.input(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Open Sans', sans-serif;\n  font-size: 13px;\n  font-weight: 400;\n  color: ", ";\n  background-color: ", ";\n  border: none;\n  margin: 0;\n  /* box-sizing: border-box; */\n  padding: ", "px ", "px;\n  /* border: ", "px ", "px solid\n    ", "; */\n  outline: none;\n  flex-grow: 2;\n  ::-webkit-input-placeholder {\n    color: ", ";\n  }\n  ::placeholder {\n    color: ", ";\n  }\n  :disabled {\n    background-color: ", ";\n  }\n"], ["\n  font-family: 'Open Sans', sans-serif;\n  font-size: 13px;\n  font-weight: 400;\n  color: ", ";\n  background-color: ",
    ";\n  border: none;\n  margin: 0;\n  /* box-sizing: border-box; */\n  padding: ", "px ", "px;\n  /* border: ", "px ",
    "px solid\n    ", "; */\n  outline: none;\n  flex-grow: 2;\n  ::-webkit-input-placeholder {\n    color: ", ";\n  }\n  ::placeholder {\n    color: ", ";\n  }\n  :disabled {\n    background-color: ", ";\n  }\n"])), function (props) { return (props.disabled ? props.blurredColor : props.textColor); }, function (props) {
    return props.disabled ? props.disabledBackgroundColor : props.backgroundColor;
}, function (props) { return paddings[props.padding].x; }, function (props) { return paddings[props.padding].y; }, function (props) { return paddings[props.padding].x; }, function (props) {
    return paddings[props.padding].y;
}, function (props) { return (props.disabled ? props.disabledBackgroundColor : props.backgroundColor); }, function (props) { return props.blurredColor; }, function (props) { return props.blurredColor; }, function (props) { return props.disabledBackgroundColor; });
export var StyledInputContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  flex-wrap: nowrap;\n  border: 0.5px solid ", ";\n  margin: 0;\n  width: 100%;\n  max-width: 100%;\n  /* //calc(100% - ", "px); */\n  border-radius: 2px;\n  outline: none;\n  background-color: ", ";\n  :focus {\n    border: 0.5px solid ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  flex-wrap: nowrap;\n  border: 0.5px solid ", ";\n  margin: 0;\n  width: 100%;\n  max-width: 100%;\n  /* //calc(100% - ", "px); */\n  border-radius: 2px;\n  outline: none;\n  background-color: ",
    ";\n  :focus {\n    border: 0.5px solid ", ";\n  }\n"])), function (props) { return props.errorColor || props.blurredColor; }, function (props) { return paddings[props.padding].x * 2; }, function (props) {
    return props.disabled ? props.disabledBackgroundColor : props.backgroundColor;
}, function (props) { return props.errorColor || props.focusColor; });
var StyledTextArea = styled.textarea(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: 'Open Sans', sans-serif;\n  font-size: 13px;\n  font-weight: 400;\n  color: ", ";\n  border: 0.5px solid ", ";\n  margin: 0;\n  padding-left: ", "px;\n  padding-right: ", "px;\n  padding-top: ", "px;\n  padding-bottom: ", "px;\n  width: calc(100% - ", "px);\n  border-radius: 2px;\n  outline: none;\n  background-color: ", ";\n  :focus {\n    border: 0.5px solid ", ";\n  }\n  :disabled {\n    background-color: ", ";\n  }\n  ::-webkit-input-placeholder {\n    /* Edge */\n    color: ", ";\n  }\n  ::placeholder {\n    color: ", ";\n  }\n"], ["\n  font-family: 'Open Sans', sans-serif;\n  font-size: 13px;\n  font-weight: 400;\n  color: ", ";\n  border: 0.5px solid ", ";\n  margin: 0;\n  padding-left: ", "px;\n  padding-right: ", "px;\n  padding-top: ", "px;\n  padding-bottom: ", "px;\n  width: calc(100% - ", "px);\n  border-radius: 2px;\n  outline: none;\n  background-color: ",
    ";\n  :focus {\n    border: 0.5px solid ", ";\n  }\n  :disabled {\n    background-color: ", ";\n  }\n  ::-webkit-input-placeholder {\n    /* Edge */\n    color: ", ";\n  }\n  ::placeholder {\n    color: ", ";\n  }\n"])), function (props) { return (props.disabled ? props.blurredColor : props.textColor); }, function (props) { return props.errorColor || props.blurredColor; }, function (props) { return paddings[props.padding].x; }, function (props) { return paddings[props.padding].x; }, function (props) { return paddings[props.padding].y; }, function (props) { return paddings[props.padding].y; }, function (props) { return paddings[props.padding].x * 2; }, function (props) {
    return props.disabled ? props.disabledBackgroundColor : props.backgroundColor;
}, function (props) { return props.errorColor || props.focusColor; }, function (props) { return props.disabledBackgroundColor; }, function (props) { return props.blurredColor; }, function (props) { return props.blurredColor; });
export var Label = styled.label(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-family: 'Open Sans', sans-serif;\n  font-size: 13px;\n  font-weight: 600;\n  color: ", ";\n  margin: 0;\n  padding: 0;\n  margin-bottom: 5px;\n  display: inline-block;\n"], ["\n  font-family: 'Open Sans', sans-serif;\n  font-size: 13px;\n  font-weight: 600;\n  color: ", ";\n  margin: 0;\n  padding: 0;\n  margin-bottom: 5px;\n  display: inline-block;\n"])), function (props) { return props.color; });
export var Description = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-family: 'Open Sans', sans-serif;\n  font-size: 12px;\n  font-weight: 400;\n  color: ", ";\n  margin: 0;\n  padding: 0;\n  margin-top: 5px;\n  display: inline-block;\n"], ["\n  font-family: 'Open Sans', sans-serif;\n  font-size: 12px;\n  font-weight: 400;\n  color: ", ";\n  margin: 0;\n  padding: 0;\n  margin-top: 5px;\n  display: inline-block;\n"])), function (props) { return props.color; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
