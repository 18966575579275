var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from './common/theme';
export var Divider = function (_a) {
    var _b = _a.color, color = _b === void 0 ? 'white' : _b, _c = _a.height, height = _c === void 0 ? '12px' : _c, style = _a.style, className = _a.className;
    var theme = useContext(ThemeContext);
    return React.createElement(StyledDivider, { color: theme[color], height: height, style: style, className: className });
};
var StyledDivider = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0;\n  padding: 0;\n  border: none;\n  background-color: ", ";\n  height: ", ";\n"], ["\n  margin: 0;\n  padding: 0;\n  border: none;\n  background-color: ", ";\n  height: ", ";\n"])), function (props) { return props.color; }, function (props) { return props.height; });
var templateObject_1;
