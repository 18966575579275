var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from './common/theme';
import { Tab, Tabs as RTTabs, TabList, TabPanel } from 'react-tabs';
import './css/tabs.css';
export var Tabs = function (_a) {
    var currentTab = _a.currentTab, tabs = _a.tabs, _b = _a.onTabChange, onTabChange = _b === void 0 ? function () { } : _b, className = _a.className, children = _a.children;
    var theme = useContext(ThemeContext);
    return (React.createElement(RTTabs, { selectedIndex: tabs.findIndex(function (_a) {
            var key = _a.key;
            return key === currentTab;
        }), onSelect: function (tabIndex) { return onTabChange(tabs[tabIndex].key); } },
        React.createElement(TabList, { style: {
                border: 'none',
                outline: 'none',
                display: 'flex',
                margin: 0,
            } }, tabs.map(function (_a) {
            var key = _a.key, label = _a.label, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
            return (React.createElement(Tab, { key: key, disabled: disabled }, label));
        })),
        tabs.map(function (_a) {
            var key = _a.key, child = _a.child;
            return (React.createElement(TabPanel, { key: key, style: {
                    backgroundColor: theme['white'],
                } },
                React.createElement(ChildContainer, { background: theme['white'] }, child)));
        })));
};
var ChildContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 11px;\n  padding: 11px;\n"], ["\n  background-color: ", ";\n  border-radius: 11px;\n  padding: 11px;\n"])), function (props) { return props.background; });
var templateObject_1;
