var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from 'react';
import { ThemeContext } from './common/theme';
import { IconSelect, iconNames as _iconNames } from '../assets/icons/GeneratedIcons';
export var iconNames = _iconNames;
export var Icon = function (_a) {
    var name = _a.name, _b = _a.color, color = _b === void 0 ? 'white' : _b, _c = _a.size, size = _c === void 0 ? '1em' : _c, onClick = _a.onClick, _d = _a.style, style = _d === void 0 ? {} : _d, className = _a.className;
    var theme = useContext(ThemeContext);
    return (React.createElement(IconSelect, { name: name, iconProps: {
            color: theme[color],
            style: __assign({ width: size, height: size }, style),
            className: className,
            onClick: onClick || undefined,
        } }));
};
