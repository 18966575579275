var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from './common/theme';
import { Icon } from './Icon';
var nextDirection = function (current) {
    switch (current) {
        case 'asc':
            return 'desc';
        case 'desc':
            return 'asc';
        case null:
            return 'asc';
    }
};
export function Table(_a) {
    var columns = _a.columns, records = _a.records, order = _a.order, onOrder = _a.onOrder, style = _a.style;
    var theme = useContext(ThemeContext);
    return (React.createElement(StyledTable, { cellSpacing: 0, style: style },
        React.createElement("thead", null,
            React.createElement("tr", null, columns.map(function (_a) {
                var key = _a.key, name = _a.name, orderable = _a.orderable;
                return (React.createElement(StyledTh, { color: theme['black'], key: name, onClick: function () {
                        return orderable &&
                            onOrder &&
                            onOrder({
                                key: key,
                                direction: nextDirection(order.key === key ? order.direction : null),
                            });
                    } },
                    React.createElement(ThContainer, null,
                        React.createElement("span", { style: { marginRight: '6px' } }, name),
                        order.key === key ? (React.createElement(Icon, { color: "black", size: "18px", name: order.direction === 'asc' ? 'angle-down' : 'angle-up' })) : (React.createElement("div", { style: { width: '18px', height: '18px' } })))));
            }))),
        React.createElement(StyledBody, { color1: theme['white'], color2: theme['gray20'] }, records.map(function (record, i) { return (React.createElement(StyledTr, { key: i, color: i % 2 === 0 ? theme['white'] : theme['gray20'] }, columns.map(function (column) { return (React.createElement(StyledTd, { key: column.name, color: theme['black'] }, column.render ? column.render(record) : record[column.key])); }))); }))));
}
var StyledTable = styled.table(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var StyledTh = styled.th(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Open Sans', sans-serif;\n  font-size: 13px;\n  font-weight: 800;\n  line-height: 24px;\n  color: ", ";\n  text-align: left;\n  padding-top: 14px;\n  padding-bottom: 14px;\n  padding-left: 20px;\n"], ["\n  font-family: 'Open Sans', sans-serif;\n  font-size: 13px;\n  font-weight: 800;\n  line-height: 24px;\n  color: ", ";\n  text-align: left;\n  padding-top: 14px;\n  padding-bottom: 14px;\n  padding-left: 20px;\n"])), function (props) { return props.color; });
var StyledBody = styled.tbody(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
var StyledTr = styled.tr(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: ", ";\n"], ["\n  background-color: ", ";\n"])), function (props) { return props.color; });
var StyledTd = styled.td(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-family: 'Open Sans', sans-serif;\n  font-size: 13px;\n  line-height: 18px;\n  font-weight: 400;\n  color: ", ";\n  padding-top: 14px;\n  padding-bottom: 14px;\n  padding-left: 20px;\n"], ["\n  font-family: 'Open Sans', sans-serif;\n  font-size: 13px;\n  line-height: 18px;\n  font-weight: 400;\n  color: ", ";\n  padding-top: 14px;\n  padding-bottom: 14px;\n  padding-left: 20px;\n"])), function (props) { return props.color; });
var ThContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin-bottom: 20px;\n"], ["\n  display: flex;\n  align-items: center;\n  margin-bottom: 20px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
