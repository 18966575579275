var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from './common/theme';
import { Icon } from './Icon';
export var Tag = function (_a) {
    var text = _a.text, _b = _a.color, color = _b === void 0 ? 'primary100' : _b, iconRight = _a.iconRight, style = _a.style;
    var theme = useContext(ThemeContext);
    return (React.createElement(StyledTag, { color: theme[color], style: style },
        React.createElement("span", { style: {
                margin: 0,
                marginRight: iconRight && text ? '7px' : 0,
                padding: 0,
            } }, text),
        iconRight && React.createElement(Icon, { name: iconRight })));
};
var StyledTag = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  font-family: 'Open Sans', sans-serif;\n  font-size: 10px;\n  font-weight: 600;\n  border: none;\n  background-color: ", ";\n  color: white;\n  white-space: nowrap;\n  border-radius: 20px;\n  padding-left: 6px;\n  padding-right: 6px;\n  padding-top: 2px;\n  padding-bottom: 2px;\n  text-align: center;\n"], ["\n  display: inline-block;\n  font-family: 'Open Sans', sans-serif;\n  font-size: 10px;\n  font-weight: 600;\n  border: none;\n  background-color: ", ";\n  color: white;\n  white-space: nowrap;\n  border-radius: 20px;\n  padding-left: 6px;\n  padding-right: 6px;\n  padding-top: 2px;\n  padding-bottom: 2px;\n  text-align: center;\n"])), function (props) { return props.color; });
var templateObject_1;
