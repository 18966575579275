var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from 'react';
import { compose } from 'ramda';
import { ThemeContext } from './common/theme';
import { Container, Description, StyledInputContainer, StyledInput, Label, } from './Input';
var capitalize = function (s) { return (s.length === 0 ? '' : s.charAt(0).toUpperCase() + s.slice(1)); };
var splitDashes = function (s) { return s.split(/_|-/); };
var join = function (s) { return s.join(' '); };
export var dashesToText = compose(join, splitDashes, capitalize);
var noop = function () {
    var s = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        s[_i] = arguments[_i];
    }
};
export function NumericInput(_a) {
    var name = _a.name, step = _a.step, autocomplete = _a.autocomplete, _b = _a.size, size = _b === void 0 ? 'small' : _b, _c = _a.label, label = _c === void 0 ? dashesToText(name) : _c, _d = _a.placeholder, placeholder = _d === void 0 ? label : _d, _e = _a.outlineColor, outlineColor = _e === void 0 ? 'primary100' : _e, description = _a.description, _f = _a.errorText, errorText = _f === void 0 ? description : _f, _g = _a.valid, valid = _g === void 0 ? true : _g, _h = _a.disabled, disabled = _h === void 0 ? false : _h, _j = _a.onFocus, onFocus = _j === void 0 ? noop : _j, _k = _a.onBlur, onBlur = _k === void 0 ? noop : _k, onChange = _a.onChange, value = _a.value, rightWidget = _a.rightWidget, _l = _a.disabledBackgroundColor, disabledBackgroundColor = _l === void 0 ? 'gray20' : _l, style = _a.style, className = _a.className;
    var theme = useContext(ThemeContext);
    return (React.createElement(Container, { style: style, className: className },
        label !== '' && (React.createElement(Label, { htmlFor: name, color: theme['black'] }, label)),
        React.createElement(StyledInputContainer, { padding: size, focusColor: theme[outlineColor], textColor: theme['black'], blurredColor: theme['gray100'], errorColor: valid ? undefined : theme['danger100'], disabled: disabled, backgroundColor: theme['white'], disabledBackgroundColor: theme[disabledBackgroundColor] },
            React.createElement(StyledInput, __assign({}, { name: name, step: step, placeholder: placeholder, onFocus: onFocus, onBlur: onBlur, disabled: disabled }, { size: 1, type: "number", autoComplete: autocomplete, onChange: onChange && !disabled ? function (ev) { return onChange(parseFloat(ev.target.value)); } : noop, value: value, padding: size, focusColor: theme[outlineColor], textColor: theme['black'], blurredColor: theme['gray100'], errorColor: valid ? undefined : theme['danger100'], backgroundColor: theme['white'], disabledBackgroundColor: theme[disabledBackgroundColor] })),
            rightWidget),
        description && React.createElement(Description, { color: theme['gray100'] }, description),
        !valid && React.createElement(Description, { color: theme['danger100'] }, errorText)));
}
